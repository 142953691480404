.fe-admin {
    & > .card {
        & > .card-header {
            position: fixed;
            width: 100%;
            z-index: 10;
            & + .card-body {
                margin-top: 24px;
            }
        }
    }
}
