.rooms-list, .room-points-list {
  list-style: none;
  padding: 0;
  margin: 0;
  > li {
    display: block;
  }
}

.rooms-list {
  > li {
    border-top: 1px solid #888;
    .room-name {
      font-size: 1.4rem;
      font-weight: bold;
      padding: 7px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .btn-room-remove, .btn-room-edit {
        margin-top: -2px;
      }
      .btn-room-edit {
        i {
          float: left;
          line-height: 25px;
          margin-bottom: -1px;
        }
      }
    }
    .protocol-add-room {
      line-height: 48px;
      display: inline-block;
    }
  }
}

#customer-name {
  margin-top: 10px;
  width: 100%;
}

.room-points-list {
  > li {
    padding-left: 15px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #d8d8d8;
    @include respond-below("sm") {
      flex-wrap: wrap;
    }
    .room-point-name {
      flex-grow: 1;
      line-height: 48px;
      white-space: nowrap;
      margin-right: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      .btn {
        margin: 5px 20px 0 0;
        @include respond-below("md") {
            margin: 5px 0 0 10px;
        }
      }
    }
    .room-point-rating {
      width: 292px;
      display: flex;
      flex-shrink: 0;
      > .room-custom-input-wrapper {
        width: 48px;
        line-height: 0;
      }
    }
    .room-point-comment {
      width: 48px;
      text-align: right;
      padding-top: 5px;
      flex-shrink: 0;
      padding-right: 15px;
      .btn:focus {
        box-shadow: 0 0 0 2px rgba(0,72,157,0.5);
      }
    }
    .room-point-attachments {
      width: 48px;
      text-align: right;
      padding-top: 5px;
      flex-shrink: 0;
      padding-right: 15px;
      margin-left: 11px;
      .btn:focus{
        box-shadow: 0 0 0 2px rgba(0,72,157,0.5);
      }
    }
    .room-add-point {
      line-height: 48px;
    }
  }
}

.rooms-list.mode-edit {
  .room-points-list {
    > li {
      &:last-child {
        justify-content: flex-start;
      }
    }
  }
}

.total-point {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  border-top: 1px solid #888;
  .total-title {
    flex-grow: 1;
    line-height: 48px;
    font-size: 1.4rem;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .total-rating {
    width: 192px;
    display: flex;
    margin-right: 100px;
    > div {
      width: 48px;
      line-height: 0;
    }
  }
}

.yesno{
  .total-rating {
    width: 81px !important;
  }
  .room-point-rating {
    width: 181px !important;
  }
  .rating-texts {
    width: 81px !important;
  }
}

.protocol-signatures {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  border-top: 1px solid #888;
  padding-top: 20px;
  .signature-container {
    margin-bottom: 15px;
    &:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      flex-grow: 1;
      margin-left: 15px;
    }
    .signature-title {
      font-weight: bold;
      width: 240px;
    }
    .signature-wrapper {
      height: 60px;
      width: 240px;
      border-bottom: 1px solid #000;
      img {
        height: 100%;
        width: auto;
      }
    }
  }
}

.room-custom-input {
  display: none;
  + label {
    cursor: pointer;
    font-weight: 400;
    padding: 5px 5px;
    margin-bottom: 0;
    position: relative;
    &:before {
      //content: "\f00d";
      content: " ";
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      display: block;
      height: 38px;
      width: 38px;
      background-color: #fff;
      border: 2px solid #a0a0a0;
      line-height: 36px;
      color: transparent;
      text-align: center;
      font-size: 24px;
    }
    &:hover {
      &:before {
        border: 2px solid #000;
      }
    }
  }
  &:disabled {
    + label {
      cursor: default;
      &:hover {
        &:before {
          border: 2px solid #a0a0a0;
        }
      }
    }
    &:checked {
      + label {
        &:hover {
          &:before {
            border: 2px solid #000;
          }
        }
      }
    }
  }
  &:checked {
    + label {
      &:before {
        border: 2px solid #000;
        color: #000;
      }
      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 30px;
        background-color: #000;
        top: 23px;
        left: 9px;
        &:first-child {
          transform: rotateZ(45deg);
        }
        &:last-child {
          transform: rotateZ(-45deg);
        }
      }
    }
  }
}

.modal {
  .modal-full {
    height: calc(100% - 3.5rem);
  }
  .modal-full,
  .modal-full-w {
    max-width: 100%;
  }
}

.container-modal {
  left: 50%;
  transform: translateX(-50%);
}

.info-bar {
  height: 46px;
}

.rating-texts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 30px;
  .spacer-before {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
  }
  .rating-texts {
    width: 192px;
    display: flex;
    margin-right: 100px;
    > div {
      width: 48px;
      writing-mode: tb;
      padding-bottom: 10px;
      line-height: 52px;
      text-align: end;
      span {
        display: inline-block;
        transform: rotateZ(180deg);
      }
    }
  }
}

.protocol-page {
  background-color: #fff;
  color: #333;
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #333;
  }
}

.sb-select {
    width: 200px;
    height: 40px;
    max-width: 75%;
    max-height: 100%;
}

.sb-select-label {
    display: none;
}

#attachments_modal {

  .attachments-container {
    min-height: 20em;
  }

  .attachment-template {
    display: none;
  }

  form {
    width: 100%;
  }
}

.attachments-container {
  display: block !important;
  border-top: none !important;
  margin-top: 5px;

  .img-container {
    max-width: 100%;
    height: 10em;
    border: 1px solid #989898;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    button {
      position: absolute;
      right: 15px;
      bottom: 0px;
      border-width: 1px;
      font-size: 2rem;
      padding: 2px 5px;
      line-height: 1;
      background-color: white;
    }

    button:hover {
      background-color: #00489d;
    }
  }
}

@media screen {
    .comment-print-wrapper {
      display: none;
    }
}

@media only screen and (min-width: 768px) {
    .sb-select-label {
        display: inline;
    }
}
