.bg-primary {
    background-color: $primary !important;
}

.btn-primary {
    background-color: $primary;
    border-color: $primary;
    &:not(:disabled) {
        &:active,
        &:focus,
        &:hover {
            background-color: darken($primary, 10%) !important;
            border-color: darken($primary, 10%) !important;
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem fade-out($primary, 0.5);
        }
    }
}

.btn-3q {
    width: 75%;
}

/*.btn-secondary {
    background-color: $secondary;
    border-color: $secondary;
    &:active,
    &:focus,
    &:hover {
        background-color: darken($secondary, 10%) !important;
        border-color: darken($secondary, 10%) !important;
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem fade-out($secondary, 0.5);
    }
}*/

.btn-primary.rounded-circle {
    padding: 0.4rem;
    font-size: 0.8rem;
    line-height: 0.8rem;
}

.userinfo {
    .dropdown-menu {
        margin-top: 3px;
    }
    a.dropdown-item {
        padding: .375rem .75rem
    }
}

.modal-backdrop.fade.in {
    opacity: .5;
}

.modal.fade.in {
    z-index: 1080;
    top: 20px;
    padding-top: 20px;
    opacity: 1;
}

.dropdown-menu {
    min-width: 8rem;
}

header {
    .btn:disabled {
        opacity: 1;
    }
}
