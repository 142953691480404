@font-face {
  font-family: 'Frutiger Next';
  src: url('/assets/fonts/frutiger_next.woff2') format('woff2'),
       url('/assets/fonts/frutiger_next.woff') format('woff')
}

@font-face {
  font-family: 'Frutiger Next';
  font-weight: bold;
  src: url('/assets/fonts/frutiger_next_bold.woff2') format('woff2'),
       url('/assets/fonts/frutiger_next_bold.woff') format('woff')
}

@font-face {
  font-family: 'Frutiger Next Cn';
  src: url('/assets/fonts/frutiger_next_cn.woff2') format('woff2'),
       url('/assets/fonts/frutiger_next_cn.woff') format('woff')
}

.border-gray {
  border: 1px solid $gray-200;
}
.bg-primary-color {
  background-color: $primary !important;
}
.bg-primary-lighter {
  background-color: $primary-lighter;
  color: $white;
}
.bg-secondary-color {
  background-color: $secondary !important;
}
.bg-yellow-light {
  background-color: $yellow-light !important;
}
.bg-yellow {
  background-color: $yellow !important;
}
.bg-green {
  background-color: $bg-green !important;
}
.bg-gray {
  background-color: $gray-200;
}
.bg-gradient {
  background: linear-gradient(to left, $yellow, $primary) !important;
}
.w-80 {
  width: 80%;
}
.w-48 {
  width: 48%;
}
.table-wrapper {
  overflow: scroll;
}
.hidden {
  display: none;
}
.card {
  width: 100%;
  margin-bottom: 20px;
}

.btn {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
}

.wrapper {
  display: flex;
  flex-flow: row wrap;
  & > * {
    flex: 1 100%;
  }
}

.container-fluid > .pcms-collection > .container {
  max-width: 100% !important;
}

.content {
  width: 100%;
  margin-top: calc(#{$header-height} + #{$headerbar-height});
  flex: 1 0px;
  min-height: 80vh;
  @media screen and (max-width: 575px) {
    margin-top: calc(#{$header-height} + 80px);
  }
}

header {
  order: 1;
  z-index: 10;
}
.nav {
  order: 2;
}
.content {
  order: 3;
}
footer {
  order: 4;
}
.subfooter {
  order: 5;
}

.pdfbox {
  position: relative;
  .box-inner {
    height: 220px;
    overflow: hidden;
    &.border-top {
      border-color: $body-bg;
    }
    .textbox {
      height: 126px;
    }
  }
  h3 {
    color: $gray-700;
    font-size: 1rem;
    margin: 0;
    i {
      color: $secondary;
    }
  }
  &.bg-primary-lighter {
    h3  {
      background: $primary-lighter;
      color: $white;
      i {
        color: $yellow;
      }
    }
  }
  .more {
    position: absolute;
    height: 25px;
    bottom: 0px;
    width: 100%;
    display: block;
    background: $gray-200;
    color: $gray-700;
    text-align: center;
  }
  .table {
    margin: 0;
    th {
      border-top: 0;
      color: $dark;
    }
    td,
    th {
      padding: 0.5rem;
      border-width: 1px;
    }
  }
}
.createticket {
  h3 {
    color: $gray-700;
    font-size: 1rem;
    margin: 0;
    i {
      color: $secondary;
    }
  }
}
.usermenu {
  position: absolute;
  top: 0;
  right: 0;
}
.userinfo {
  background: $primary;
  padding: 4px 20px;
  color: $white;
  text-align: right;
}

.showmenu {
  cursor: pointer;
  i {
    display: none;
    width: 60px;
  }
  .fa-bars {
    display: block;
  }
  &.active {
    i {
      display: none;
    }
    .fa-times {
      display: block;
    }
  }
}

.tickettable {
  &.table-hover {
    cursor: pointer;
  }
}
.tickettable-wrapper {
  overflow: scroll;
  .tickettable {
    min-width: 800px;
  }
}
.max-input-width {
  width: 100%;
  max-width: 500px;
}

textarea:read-only {
  background: transparent;
  border: none;
  cursor: default;
  outline: none;
}

@media only screen and (max-width: 1300px) {
  .nav {
    max-width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  header .usermenu .hideonmobile {
    display: none;
  }
  .doklist {
    th {
      display: none;
    }
  }
  .pdfbox {
    .box-inner {
      height: auto;
      .textbox {
        height: auto;
      }
    }
    .doklist {
      margin-bottom: 20px;
    }
  }

  #qs-subbuilding,
  #qs-year {
    margin-right: 0 !important;
  }

}
@media only screen and (max-width: 500px) {
  .hideonmobile {
    display: none !important;
  }

  .w-80,
  .w-48 {
    width: 100%;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 1200px) {
  .hidemobilenav {
    display: none;
  }
}


@media screen and (min-width: 1200px) {
  .showmenu {
    display: none !important;
  }
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 10px 0;
    border-radius: 4px;
    > li {
        display: inline;
        > a, > span {
            position: relative;
            float: left;
            padding: 6px 12px;
            line-height: 1.42857143;
            text-decoration: none;
            color: #337ab7;
            background-color: #fff;
            border: 1px solid #ddd;
            margin-left: -1px;
            &:hover, &:focus {
                z-index: 3;
                color: #23527c;
                background-color: #eee;
                border-color: #ddd;
            }
        }
        &:first-child > a, &:first-child > span {
            margin-left: 0;
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
        }
        &:last-child > a, &:last-child > span {
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
        }
    }
    > .active > a, > .active > span, > .active > a:hover, > .active > span:hover, > .active > a:focus, > .active > span:focus {
        z-index: 2;
        color: #fff;
        background-color: $primary;
        border-color: $primary;
        cursor: default;
    }
    > .disabled > span, > .disabled > span:hover, > .disabled > span:focus, > .disabled > a, > .disabled > a:hover, > .disabled > a:focus {
        color: #777;
        background-color: #fff;
        border-color: #ddd;
        cursor: not-allowed;
    }
}
#ticketList_wrapper {
    display: block;
    .table-controls {
        .dataTables_info {
            position: static;
            padding-top: 8px;
        }
        #ticketList_length {
            select {
                width: 100px;
            }
        }
    }
}
#ticketList {
    > thead {
        > tr {
            > th {
                padding-right: 24px;
                white-space: nowrap;
            }
        }
    }
}

.dataTable {
    a {
        color: $primary;
    }
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all .15s ease-in-out;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'%3E%3C/path%3E%3C/svg%3E");
  background-position: calc(100% - 5px) calc(50% + 2px);
  background-repeat: no-repeat;
  &::-ms-expand {
    display: none;
  }
}

.custom-file-label {
    padding-right: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.building-dropdown, .user-dropdown {
    .dropdown-menu {
        min-width: 100%;
    }
}

.building-dropdown {
  position: relative !important;
  .dropdown-menu {
    max-height: 90vh;
    overflow-y: auto;
  }
}

@media screen and (max-width: 575px) {
  .building-dropdown {
    .dropdown-menu {
      min-width: 95vw !important;
      max-width: 95vw;
      .dropdown-item {
        white-space: normal;
      }
    }
  }
}

.form-actions {
    @media screen and (max-width: 575px) {
        flex-direction: column;
        .btn {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

#dropdownMenuButton1 {
    text-align: left;
    &:after {
        float: right;
        margin-top: 10px;
        margin-left: 10px;
    }
}

.info-bottom div.dataTables_info {
    top: initial;
}
