.ui-canvas-drawing {
    &.auto-sizing, &.fixed-sizing {
        display: flex;
        flex-direction: column;
        .ui-canvas-drawing-canvas-wrapper {
            flex-grow: 1;
            canvas {
                display: block;
                border: 1px solid black;
                background-color: white;
            }
        }
        .ui-canvas-drawing-actions {
            margin-bottom: 10px;
            button {
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
            .ui-canvas-drawing-clear {
                float: right;
            }
        }
    }
}
