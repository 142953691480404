// Variables

// Restated Bootstrap Variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000 !default;

$primary: #00489d;
//$secondary: #aeb7e2;
$bg-green: #eaffdb;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #fff22e;
$yellow-light: #ffffbf;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$primary-darker: darken(#283c86, 16%);
$primary-bright: lighten(#283c86, 16%);
$primary-lighter: #1e5baa;

$theme-colors: (
  "primary": $primary,
  //"secondary": $secondary,
  "warning": $yellow
);

$body-color: $gray-600;
//$link-color: $secondary;
$body-bg: #f6f6f6;

$font-family-sans-serif: "Frutiger Next", Arial, sans-serif;
$headings-font-family: "Frutiger Next Cn";

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.4;
$h2-font-size: $font-size-base * 1.6;
$h3-font-size: $font-size-base * 1.4;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

$headings-font-family: Tahoma, $font-family-sans-serif;
$headings-font-weight: 400;
$headings-line-height: 1.1 !default;
$headings-color: $gray-700;

$enable-rounded: false;
$enable-shadows: false;
$enable-print-styles: false;

$header-height: 73px;
$headerbar-height: 46px;
