.sortable-box-placeholder {
    border:1px dashed #aaa;
    background:#f6f6f6;
    display: block;
    margin-top: 0px !important;
    margin-bottom: 24px !important;
}

.sortContainer .editbar, .sortContainerHorizontal .portlet-header .block-title {
    cursor:move;
}

.sortContainer {
    min-height: 50px;
}

.landing-zone {
    outline: 3px dashed #DDDDDD;
}
