nav {
	position: fixed;
	z-index: 2;
	height: 100%;
	width: 240px;
	margin-top: calc(#{$header-height} + #{$headerbar-height});
	background: $primary;
	.nav {
		flex: 1 auto;
		max-width: 240px;
		> :first-child {
			border-top: 1px solid #AEB7E2;
		}

		.nav-item {
			&:hover a {
				text-decoration: underline;
			}
			.nav {
				display: none;
				margin-left:16px;
				a {
					text-decoration: none;
				}
				.nav-item:hover a {
					text-decoration: underline;
				}
			}

			&.open {
				> a {
					font-weight: bold;
					border-bottom-width: 0px;
				}

				.nav {
					display: block;
					> :first-child {
						border-top-width: 0px;
					}
					a {
						padding-left: 8px;
					}
				}
			}
			&.active {
				> a {
					font-weight: bold;
				}
			}
		}
		a {
			color: $white;
			padding-top: 10px;
			padding-bottom: 10px;
			border-bottom: 1px solid #AEB7E2;
			font-weight: 300;
			text-decoration: none;
			&.active {
			  font-weight: bold;
			}
			i {
			  width: 36px;
			  margin-right: 4px;
			}
		}
	}
	.nav-link:not(.active):hover {
		text-decoration: underline;
	}
}
@media screen and (min-width: 992px) {
	.navactive {
		.content,
		footer,
		.subfooter {
			margin-left: 240px;
		}
	}
}
@media screen and (max-width: 991px) {
	nav {
		width: 100%;
		margin-top: $header-height;
		z-index: 20;
		.nav {
			max-width: 100%;
			a.nav-link {
			  padding-left: 2rem;
			  padding-right: 2rem;
			}
		}
	}
}
.nav.nav-tabs {
		border-bottom-width: 3px;
		.nav-item {
			  margin-bottom: -3px;
			  .nav-link {
			      border: 0;
			      border-bottom: 3px solid transparent;
			      color: $gray-700;
			      &:hover, &:focus {
			          background-color: transparent;
			          border-color: transparent;
			          color: $primary;
			      }
			      &.active, &.active:hover {
			          border-color: $primary;
			          color: $primary;
			      }
			  }
		}
}
.protocol-list-tabs {
		position: relative;
		z-index: 2;
}
#protocol-list-content {
		margin-top: -1px;
}
